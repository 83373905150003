.form {
  display: flex;
  flex-direction: column;
  align-items: center; }

.consent-container {
  display: flex;
  flex-direction: column; }

.consent-box {
  margin-bottom: 20px; }

.input {
  font-size: 1.2rem;
  padding: 15px;
  margin: 20px;
  width: 100%;
  border: 1px solid $raknroll-red; }

@media only screen and (min-width: 680px) {

  .consent-container {
    display: flex;
    flex-direction: column;
    max-width: 900px; }

  .input {
    font-size: 1.6rem;
    width: 500px; } }
