@import url(https://fonts.googleapis.com/css?family=Dosis);
.logo {
  height: 240px; }

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 140px; }

.main {
  width: 100%;
  padding: 0 30px 30px 60px; }

.title {
  font-size: 1.4rem;
  text-align: center;
  font-weight: bold; }

.paragraph {
  font-size: 1.2rem;
  margin: 20px 0;
  text-align: center; }

.button-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column; }

.option-container {
  display: flex;
  flex-direction: column;
  align-items: center; }

.thankyou-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center; }

.thankyou-link {
  color: #ee1c25;
  text-decoration: none; }

.no-results-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 1.6rem; }

.footer {
  margin: 20px 30px; }

@media only screen and (min-width: 680px) {
  .title {
    font-size: 1.8rem;
    text-align: center;
    font-weight: bold; }
  .paragraph {
    font-size: 1.6rem;
    margin: 20px;
    text-align: center; }
  .button-container {
    flex-direction: row; } }

.button {
  background-color: #ee1c25;
  color: #ffffff;
  font-size: 1.2rem;
  padding: 15px 30px;
  margin: 15px 20px;
  border: 0;
  width: 100%; }

@media only screen and (min-width: 680px) {
  .button {
    font-size: 1.6rem;
    margin: 0px 20px;
    width: 300px; } }

.form {
  display: flex;
  flex-direction: column;
  align-items: center; }

.consent-container {
  display: flex;
  flex-direction: column; }

.consent-box {
  margin-bottom: 20px; }

.input {
  font-size: 1.2rem;
  padding: 15px;
  margin: 20px;
  width: 100%;
  border: 1px solid #ee1c25; }

@media only screen and (min-width: 680px) {
  .consent-container {
    display: flex;
    flex-direction: column;
    max-width: 900px; }
  .input {
    font-size: 1.6rem;
    width: 500px; } }

* {
  font-family: 'Dosis', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  color: #4D4D4D; }

body {
  margin: 0;
  padding: 0;
  background-image: url(/static/media/background.619dafc1.png);
  background-position: center top;
  background-size: 100%; }

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 1000px;
  margin: auto;
  min-height: 100vh; }

