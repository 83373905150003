.logo {
  height: 240px; }

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 140px; }

.main {
  width: 100%;
  padding: 0 30px 30px 60px; }

.title {
  font-size: 1.4rem;
  text-align: center;
  font-weight: bold; }

.paragraph {
  font-size: 1.2rem;
  margin: 20px 0;
  text-align: center; }

.button-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column; }

.option-container {
  display: flex;
  flex-direction: column;
  align-items: center; }

.thankyou-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center; }

.thankyou-link {
  color: $raknroll-red;
  text-decoration: none; }

.no-results-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 1.6rem; }

.footer {
  margin: 20px 30px; }

@media only screen and (min-width: 680px) {

  .title {
    font-size: 1.8rem;
    text-align: center;
    font-weight: bold; }

  .paragraph {
    font-size: 1.6rem;
    margin: 20px;
    text-align: center; }

  .button-container {
      flex-direction: row; } }
