@import url('https://fonts.googleapis.com/css?family=Dosis');

@import "colors";

@import "components/app";
@import "components/button";
@import "components/form";

* {
  font-family: 'Dosis', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  color: $raknroll-grey; }

body {
  margin: 0;
  padding: 0;
  background-image: url("../assets/background.png");
  background-position: center top;
  background-size: 100%; }

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 1000px;
  margin: auto;
  min-height: 100vh; }
