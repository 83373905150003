.button {
  background-color: $raknroll-red;
  color: #ffffff;
  font-size: 1.2rem;
  padding: 15px 30px;
  margin: 15px 20px;
  border: 0;
  width: 100%; }

@media only screen and (min-width: 680px) {
  .button {
    font-size: 1.6rem;
    margin: 0px 20px;
    width: 300px; } }
